import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { AnalysisData } from '../../models/AnalysisData';
import { Name } from '../../models/PatientListItemData';
import { PlotPoints } from '../../models/PlotPoints';

export interface RiskHistogramProps {
  analysis: AnalysisData;
  patientName: Name;
  height: string;
}

function NormalizeData(data) {
  const normalizedData: PlotPoints[] = [];

  for (let i = 0; i < data.length; i++) {
    const tempPoint: PlotPoints = {
      x: Math.round(data[i].x * 100),
      y: data[i].y,
    };

    normalizedData.push(tempPoint);
  }

  return normalizedData;
}

export const RiskHistogram: React.FC<RiskHistogramProps> = (props: RiskHistogramProps) => {
  const patientNameFormat = props.patientName.given + ' ' + props.patientName.family;
  const riskData = props.analysis.riskPredictionDetails.riskAgnosticPlotData;
  const subTypeData =
    props.analysis.riskPredictionDetails.riskClassificationSubtypesActive[0].plotData;

  const normalizedRiskData: PlotPoints[] = NormalizeData(riskData);
  const normalizedSubTypeData: PlotPoints[] = NormalizeData(subTypeData);

  const options = {
    chart: {
      type: 'column',
      styleMode: true,
      style: {
        fontFamily: 'Inter',
        fontSize: '12px',
      },
      height: props.height,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
      verticalAlign: 'bottom',
      style: {
        fontSize: '12px',
        color: 'var(--black)',
      },
    },
    legend: {
      align: 'left',
      x: 54,
      verticalAlign: 'bottom',
      layout: 'horizontal',
      symbolRadius: 0,
      itemStyle: {
        fontSize: '12px',
        color: 'var(--gray-100-v-2-1)',
        fontWeight: 'normal',
      },
    },
    xAxis: {
      allowDecimals: false,
      min: 0.0,
      max: 100,
      crosshair: true,
      title: {
        text: 'SYGNAL Risk Score',
        fontSize: '12px',
        color: 'var(--black)',
        padding: '10px',
      },
      plotLines: [
        {
          color: 'var(--black)',
          width: 2,
          value: props.analysis.riskPredictionDetails.riskScore * 100,
          zIndex: 100,
          dashStyle: 'Solid',
          label: {
            text: patientNameFormat,
            y: 50,
          },
        },
        {
          color: 'var(--cool-gray-20-v-2-1)',
          width: 2,
          value: 50,
          zIndex: 1,
          dashStyle: 'Dash',
        },
        {
          color: 'var(--cool-gray-20-v-2-1)',
          width: 2,
          value: 60,
          zIndex: 1,
          dashStyle: 'Dash',
        },
      ],
      plotBands: [
        {
          color: 'var(--white)',
          from: 0.0,
          to: 50,
          label: {
            text: 'Low',
            style: {
              fontSize: '12px',
              color: 'var(--gray-60-v-2-1)',
            },
          },
        },
        {
          color: 'var(--white)',
          from: 50,
          to: 60,
          label: {
            text: 'High',
            style: {
              fontSize: '12px',
              color: 'var(--gray-60-v-2-1)',
            },
          },
        },
        {
          color: 'var(--white)',
          from: 60,
          to: 100,
          label: {
            text: 'Extreme',
            style: {
              fontSize: '12px',
              color: 'var(--gray-60-v-2-1)',
            },
          },
        },
      ],
    },
    yAxis: {
      allowDecimals: false,
      min: 0,
      title: {
        text: 'Patient Count',
        style: {
          fontSize: '12px',
          color: 'var(--black)',
        },
      },
    },
    tooltip: {
      backgroundColor: 'var(--white)',
      borderColor: 'var(--white)',
    },
    plotOptions: {
      column: {
        pointPadding: 0,
        borderWidth: 0,
        borderColor: 'none',
        grouping: false,
        groupPadding: 0,
        shadow: false,
      },
    },
    series: [
      {
        name: 'All patients',
        color: 'var(--blue-40-v-2-1)',
        data: normalizedRiskData,
      },
      {
        name: props.analysis.riskPredictionDetails.riskClassificationSubtypesActive[0].label,
        color: 'var(--blue-histogram-light)',
        data: normalizedSubTypeData,
      },
    ],
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      analysis={props.analysis}
      patientName={props.patientName}
      data-test-id="risk-histogram"
    />
  );
};
