import { RootState } from '../store';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TherapyData } from '../../models/TherapyData';
import { therapyApi } from '../../api/TherapyAPI';
import { AnalysisData } from '../../models/AnalysisData';

export const THERAPY_SLICE_NAME = 'therapies';

interface TherapyListState {
  therapySearchPending: boolean;
  therapySearchError: boolean;
  therapies: TherapyData[] | null;
}

export const initialState: TherapyListState = {
  therapySearchPending: false,
  therapySearchError: false,
  therapies: [],
};

export const fetchTherapiesOnAnalysis = createAsyncThunk<TherapyData[] | null, AnalysisData>(
  `${THERAPY_SLICE_NAME}/fetchTherapiesOnAnalysis`,
  async (analysis) => {
    return therapyApi.getTherapiesOnAnalysis(analysis);
  }
);

export const therapySlice = createSlice({
  name: THERAPY_SLICE_NAME,

  initialState,

  reducers: {
    clearTherapyList: (state) => {
      return {
        ...state,
        therapies: [],
      };
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchTherapiesOnAnalysis.pending, (state) => {
        return {
          ...state,
          therapySearchPending: true,
          therapySearchError: false,
          therapySearchResult: null,
        };
      })
      .addCase(fetchTherapiesOnAnalysis.fulfilled, (state, action) => {
        return {
          ...state,
          therapySearchPending: false,
          therapySearchError: false,
          therapies: action.payload,
        };
      })
      .addCase(fetchTherapiesOnAnalysis.rejected, (state) => {
        return {
          ...state,
          therapySearchPending: false,
          therapySearchError: true,
          therapySearchResult: null,
        };
      });
  },
});

export const therapyReducer = therapySlice.reducer;

export const { clearTherapyList } = therapySlice.actions;

export const selectTherapyList = (state: RootState): TherapyData[] | null =>
  state.therapies.therapies;
export const selectTherapySearchPending = (state: RootState): boolean =>
  state.therapies.therapySearchPending;
export const selectSTherapySearchError = (state: RootState): boolean =>
  state.therapies.therapySearchError;
