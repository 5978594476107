import React from 'react';
import { TherapyTableItemData } from '../../models/TherapyData';
import { TherapyRankingLabel } from './TherapyRankingLabel';
import styles from './TherapyTable.module.scss';
import { useState } from 'react';
import { Tooltip } from 'carbon-components-react';
import { ReactComponent as ChevronDown } from './../../assets/chevron--down.svg';
import { ReactComponent as ChevronUp } from './../../assets/chevron--up.svg';
import { TherapyModal } from '../therapy-modal/TherapyModal.component';
import { Link } from 'carbon-components-react';

export interface TherapyTableProps {
  type: string;
  therapies: TherapyTableItemData[];
}

export let isExpanded = false;

export const TherapyTable: React.FC<TherapyTableProps> = (props: TherapyTableProps) => {
  const tableId = `${props.type}-table`;
  const topFivetherapies = props.therapies.slice(0, 5);
  const topTenTherapies = props.therapies.slice(0, 10);
  const allTherapies = props.therapies;

  const [therapiesToDisplay, setTherapyDisplay] = useState(topFivetherapies);
  const [expanded, setExpanded] = useState(false);
  const [modalToggle, setModalToggle] = useState(false);
  const [modalIDToggle, setModalIDToggle] = useState('');

  let numOfHiddenTherapies;
  let unlimitedTherapies = false;
  let isTrial = false;
  let moreThanFive = false;

  if (props.therapies.length > 5) {
    moreThanFive = true;
  }

  if (props.type === 'soc') {
    unlimitedTherapies = true;
    numOfHiddenTherapies = allTherapies.length - topFivetherapies.length;
  } else if (props.type === 'ct') {
    isTrial = true;
    numOfHiddenTherapies = topTenTherapies.length - topFivetherapies.length;
  } else {
    numOfHiddenTherapies = topTenTherapies.length - topFivetherapies.length;
  }

  function toggleExpansion() {
    if (!expanded) {
      setExpanded(true);
      if (unlimitedTherapies) {
        setTherapyDisplay(allTherapies);
      } else {
        setTherapyDisplay(topTenTherapies);
      }
      isExpanded = true;
    } else {
      setExpanded(false);
      setTherapyDisplay(topFivetherapies);
      isExpanded = false;
    }
  }

  function formatTrialPhase(trialPhase) {
    let res = trialPhase;
    if (trialPhase === 'NA' || !isTrial) {
      res = '-';
    } else {
      res = res.split('e').join('e ');
    }
    return res;
  }

  function formatRMST(rmstValue: string) {
    const res = rmstValue.toString().substring(0, 6);
    return res;
  }

  return (
    <div className={styles['therapy-table']}>
      <table id={tableId}>
        <thead>
          <tr>
            <th id={styles['therapy-name']}>Therapy Name</th>
            <th id={styles['therapy-drug-class']}>Drug Class</th>
            <th id={styles['therapy-RMST-value']}>
              RMST Value
              <Tooltip>
                A value that quantifies the potential expected benefit of a particular intervention
                by measuring the area between survival curves for patients with or without the drug
                target expressed.
              </Tooltip>
            </th>
            <th id={styles['therapy-trial-phase']}>Trial Phase</th>
            <th id={styles['therapy-ranking']}>Therapy Ranking</th>
          </tr>
        </thead>
        <tbody>
          {therapiesToDisplay.map((ele) => (
            <tr key={ele.therapyId}>
              <td id={styles['therapy-name']}>
                {/*This is a link to the therapy modal component*/}
                <Link
                  onClick={() => {
                    setModalToggle(true);
                    setModalIDToggle(ele.therapyId);
                  }}
                >
                  {ele.name}
                </Link>
              </td>
              <td id={styles['therapy-drug-class']}>{ele.drugClass}</td>
              <td id={styles['therapy-RMST-value']}>{formatRMST(ele.rmstValue)}</td>
              <td id={styles['therapy-trial-phase']}>{formatTrialPhase(ele.trialPhase)}</td>
              <td id={styles['ranking-label']}>
                <TherapyRankingLabel score={ele.therapyRanking} />
              </td>
            </tr>
          ))}
          {moreThanFive && (
            <div id={styles['see-more']} data-testid="see-more" onClick={() => toggleExpansion()}>
              {expanded && (
                <a>
                  See Less <ChevronUp />{' '}
                </a>
              )}
              {!expanded && (
                <a>
                  {' '}
                  See {numOfHiddenTherapies} More <ChevronDown />{' '}
                </a>
              )}
            </div>
          )}
        </tbody>
      </table>
      <TherapyModal
        open={modalToggle}
        therapyId={modalIDToggle}
        onRequestClose={() => setModalToggle(false)}
      />
    </div>
  );
};

export default TherapyTable;
