import { Modal } from 'carbon-components-react';
import './CalculationModal.component.scss';
import {
  UserProfileAlt24,
  ChartAverage24,
  ArrowRight24,
  CheckmarkOutline24,
} from '@carbon/icons-react';
import { ReactComponent as ContourFinding } from './../../assets/contour-finding.svg';

export interface CalculationModalProps {
  open: boolean; // Signals whether the modal is opened or closed
  onRequestClose: any; // Needed to trigger the modal to close after clicking the 'X'
}

export const CalculationModal: React.FC<CalculationModalProps> = (props: CalculationModalProps) => {
  return (
    <>
      {props.open && (
        <div
          className="background-shadow"
          data-testid="modal-background"
          onClick={() => {
            props.onRequestClose();
          }}
        />
      )}
      <Modal
        className="calc-modal"
        open={props.open}
        onRequestClose={props.onRequestClose}
        passiveModal // Indicates that this is a modal with no buttons
        hasForm // Needed to override the carbon component's default padding
        data-testid="calculation-modal"
      >
        <div className="calc-modal-container">
          <div className="calc-modal-content-box">
            <div className="calc-modal-title">
              <UserProfileAlt24 className="calc-modal-icon user-profile" /> Patient Information
            </div>
            <div className="calc-modal-text">
              Initial patient demographics such as age, gender, diagnosis and stage of diagnosis are
              identified.
            </div>
          </div>
          <ArrowRight24 />
          <div className="calc-modal-content-box">
            <div className="calc-modal-title">
              <ContourFinding className="calc-modal-icon" /> Genes at Play
            </div>
            <div className="calc-modal-text">
              We then identify the patient's RNA sequences and FISH Cytogenetics data to help select
              and analyze a more accurate model.
            </div>
          </div>
          <ArrowRight24 />
          <div className="calc-modal-content-box">
            <div className="calc-modal-title">
              <ChartAverage24 className="calc-modal-icon" /> Risk Model
            </div>
            <div className="calc-modal-text">
              The data gathered is then tested using the Sygnomics Model algorithm.
            </div>
          </div>
          <ArrowRight24 />
          <div className="calc-modal-content-box">
            <div className="calc-modal-title">
              <CheckmarkOutline24 className="calc-modal-icon" /> Recommendation
            </div>
            <div className="calc-modal-text">
              The output given by the SYGNAL Model provices a prognosis and treatment based on
              accuracy scores and quality grades.
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
