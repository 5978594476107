import React from 'react';
import TherapyTable from './TherapyTable';
import { TherapyData, TherapyTableItemData } from '../../models/TherapyData';
import { useAppSelector } from '../../store/hooks';
import { selectTherapyList } from '../../store/slices/therapy.slice';
import { AnalysisData } from '../../models/AnalysisData';
import { TherapyRecommendationDetails } from '../../models/TherapyRecommendationData';

export let soc_therapies: TherapyData[] = [];
export let ct_therapies: TherapyData[] = [];
export let olu_therapies: TherapyData[] = [];

let soc_table_items: TherapyTableItemData[] = [];
let ct_table_items: TherapyTableItemData[] = [];
let olu_table_items: TherapyTableItemData[] = [];

export function parseTherapyData(therapies, analysis) {
  soc_therapies = [];
  ct_therapies = [];
  olu_therapies = [];

  therapies.forEach((therapy) => {
    if (therapy.type.indexOf('SOC') >= 0) {
      soc_therapies.push(therapy);
    }

    if (therapy.type.indexOf('Clinical Trials') >= 0) {
      ct_therapies.push(therapy);
    }

    if (therapy.type.indexOf('Off-target') >= 0) {
      olu_therapies.push(therapy);
    }
  });

  soc_table_items = buildTableItems(soc_therapies, analysis);
  ct_table_items = buildTableItems(ct_therapies, analysis);
  olu_table_items = buildTableItems(olu_therapies, analysis);
}

export interface TherapyTabProps {
  analysis: AnalysisData;
}

function buildTableItems(therapies, analysis) {
  const result: TherapyTableItemData[] = [];
  therapies.forEach((therapy) => {
    const therapyAnalysisDetails = analysis.therapyRecommendationDetails.find(
      (details: TherapyRecommendationDetails) => details.id === therapy.therapyId
    );
    result.push({
      therapyId: therapy.therapyId,
      name: therapy.name,
      drugClass: therapy.drugClass,
      rmstValue: therapyAnalysisDetails.rmstValue,
      trialPhase: therapy.trialPhase,
      therapyRanking: therapyAnalysisDetails.therapyScore * 100,
    });
  });

  return result;
}

export const TherapyTab: React.FC<TherapyTabProps> = (props: TherapyTabProps) => {
  const therapyList = useAppSelector(selectTherapyList);
  parseTherapyData(therapyList, props.analysis);

  return (
    <div id="therapy-tab-content">
      <h1 className="subtitle"> Standard of Care </h1>
      <TherapyTable type="soc" therapies={soc_table_items} />
      <h1 className="subtitle"> Clinical Trials </h1>
      <TherapyTable type="ct" therapies={ct_table_items} />
      <h1 className="subtitle"> Off-Label Use</h1>
      <TherapyTable type="olu" therapies={olu_table_items} />
    </div>
  );
};

export default TherapyTab;
