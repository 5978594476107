/* SomethingWentWrong.component.tsx
 *
 *  This .tsx file contains code for the Something Went Wrong State View
 */

//Below are the imports required for this file:
//
import { Button } from 'carbon-components-react';
import { ReactComponent as Ref } from './../../assets/restart.svg';
import './somethingwrong.component.scss';

function refresh() {
  window.location.reload();
}

export const SomethingWrong: React.FC = () => {
  return (
    <div className="center">
      <p className="something-went-wrong">Something Went Wrong</p>
      <p className="we-are-sorry">
        We’re sorry, we were not able to retrieve data. Please try refreshing the page or contact admin@sygnomics.net
      </p>
      <Button
        className="bgcol"
        renderIcon={Ref}
        iconDescription="Refresh"
        kind="primary"
        onClick={refresh}
      >
        Refresh Page
      </Button>
    </div>
  );
};

/*
 * EOF
 */
