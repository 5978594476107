import { RootState } from '../store';
import { createSlice } from '@reduxjs/toolkit';

export const HEADER_SLICE_NAME = 'header';

interface HeaderState {
  isAuthenticated: boolean;
}

export const initialState: HeaderState = {
  isAuthenticated: false,
};

export const headerSlice = createSlice({
  name: HEADER_SLICE_NAME,
  initialState,
  reducers: {
    changeAuthState: (state, action) => {
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    },
  },
});

export const headerReducer = headerSlice.reducer;
export const { changeAuthState } = headerSlice.actions;
export const selectAuthState = (state: RootState): boolean => state.header.isAuthenticated;
