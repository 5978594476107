// LoadingSpinner.component.tsx
//
// This .tsx file contains code for the Loading Error State View
//

//Below are the imports required for this file:
//
import React from 'react';
import { Loading } from 'carbon-components-react';
import './spinner.component.scss';

//
// Interface LoadingSpinnerProps:
//
// (Sets up props with required data types for the carbon-component
// Loading Spinner)
//
export interface LoadingSpinnerProps {
  //begin interface LoadingSpinnerProps

  active: boolean;
  //determines whether the loading indicator should be spinning or not
  withOverlay: boolean;
  //determines whether an overlay needs to be applied along with the Spinner
  small: boolean;
  //determines whether smaller variant of spinner is required
  description: string;
  //used for description that would be used to best describe the loading state
} // end interface LoadingSpinnerProps

//
// Spinner Function:
//
// (Function now being used *WITH* Props as Interface)
//
export const Spinner: React.FC<LoadingSpinnerProps> = (props: LoadingSpinnerProps) => {
  //begin function Spinner
  return (
    //begin return of view

    <div className="loading-large">
      <Loading
        active={props.active}
        withOverlay={props.withOverlay}
        small={props.small}
        description={props.description}
        className="spinner-css"
      />
      <div>
        <p className="retrieving-patient-d">{props.description}</p>
      </div>
    </div>
  ); //end return of view
}; //end function Spinner
