import React from "react";
import { LineChart } from "@carbon/charts-react";
import "@carbon/charts/styles.css";
import sampleCurve from "./sampleCurve.json";
import chartOptions from "./chartOptions.json"
import './SurvivalCurve.scss'
import {Tile} from 'carbon-components-react'


function buildCurve(dict, groupName) {
	let new_entries = []
	for (let i = 0; i < dict.time.length; i++) {
		let key = dict.time[i]
		let value = dict.survivalProbability[i]
		let entry = {
			"group": groupName,
			"key": key,
			"value": value,
			"label": 'filled'
		}
		new_entries.push(entry)
	}
	return new_entries
}

export function buildSurvivalCurveProps(name, riskScore, survivalMonths) {
	let riskLevel = ""
	const fullName = name.given + " " + name.family
	if (riskScore <= 50) {
		riskLevel = "Low"
	} else if (riskScore > 50 && riskScore <= 60) {
		riskLevel = "High"
	} else {
		riskLevel = "Extreme"
	}
	return {"name": fullName, "riskLevel": riskLevel, "months": survivalMonths}
}

const low_entries = buildCurve(sampleCurve.low, 'Low Risk')
const high_entries = buildCurve(sampleCurve.high, 'High Risk')
const extreme_entries = buildCurve(sampleCurve.extreme, 'Extreme Risk')
const graphData = low_entries.concat(high_entries).concat(extreme_entries)

// getIsFilled not in options json because must be function 
// and adding as string does not produce desired behavior
chartOptions.getIsFilled = () => true

export const SurvivalCurve = (props) => {

	const state = { data: graphData,
	options: chartOptions
};

	return (
		<>
		<h4 className={'survival-curve-title'} data-testid="survival-curve-title">Progression Free Survival</h4>
		<Tile className={'card-with-shadow'} data-testid="card">
			<span className={'graphTitleSpan'} data-testid="survival-curve-subTitle" >Survival Curves</span>
		<div className={'chart-and-content'} data-testid="chart-and-content">
		<LineChart
			data={state.data}
			options={state.options}>
		</LineChart>
		<div className={"content-block"} data-testid = "content-block">
			<p className={'patient-name'} data-testid = "patient-name">{props.name}</p>
			<p className={`patient-risk ${props.riskLevel}-color`} data-testid = "risk-level">{props.riskLevel} Risk</p>
			<div className={`months-container ${props.riskLevel}-left-border`} data-testid = "months-container">
				{/* <div className={'coloredBar'}></div> */}
				<span className={'mpfs'}>MPFS: </span>
				<span className={'months'}>{props.months} months</span>
			</div>
			<p className={'info-paragraph'} data-testid = "paragraph">Predicted Progression Free Survival (PFS) for similar patients based on coMMpass data.</p>
		</div>
		</div> 
		</Tile>
		</>
	);
}