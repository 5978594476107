import { Modal } from 'carbon-components-react';
import './TherapyModal.component.scss';
import { ReactComponent as TherapyModalIcon } from './../../assets/therapy-modal-icon.svg';
import { selectTherapySearchPending, selectTherapyList } from '../../store/slices/therapy.slice';
import { useAppSelector } from '../../store/hooks';

import { LoadingSpinnerProps, Spinner } from '../error-stages/LoadingSpinner.component';

export interface TherapyModalProps {
  open: boolean; // Signals whether the modal is opened or closed
  therapyId: string; // ID - Needs to be checked against therapy prop
  onRequestClose: any; // Needed to trigger the modal to close after clicking the 'X'
}

const spinnerProps: LoadingSpinnerProps = {
  active: true,
  withOverlay: false,
  small: false,
  description: 'Loading Therapy Data',
};

export function formatField(field, type) {
  let res = field;
  if (field === 'NA') {
    res = '-';
  } else if (type === 'trial') {
    res = res.split('e').join('e ');
  }

  return res;
}

export const TherapyModal: React.FC<TherapyModalProps> = (props: TherapyModalProps) => {
  const therapyList = useAppSelector(selectTherapyList);
  const searchingTherapy = useAppSelector(selectTherapySearchPending);

  if (therapyList !== null) {
    //Set active therapy in the modal
    const activeTherapy = therapyList.find((obj) => obj.therapyId === props.therapyId);

    return (
      <>
        {props.open && (
          <div
            className="background-shadow"
            data-testid="modal-background"
            onClick={() => {
              props.onRequestClose();
            }}
          />
        )}
        <Modal
          open={props.open}
          onRequestClose={props.onRequestClose}
          passiveModal // Indicates that this is a modal with no buttons
          hasForm // Needed to override the carbon component's default padding
          data-testid="therapy-modal"
        >
          {searchingTherapy && <Spinner data-testid={'spinner'} {...spinnerProps} />}
          {!searchingTherapy && (
            <div className="therapy-container" data-testid="therapy-container">
              <TherapyModalIcon className="drug-icon" data-testid="drug-icon" />
              <span className="drug-title" data-testid="drug-title">
                {activeTherapy?.name}
              </span>
              <div className="drug-target-margins" data-testid="drug-target-margin">
                <p className="therapy-modal-label" data-testid="drug-target-label">
                  Drug Target
                </p>
                <p className="therapy-modal-content" data-testid="drug-target">
                  {activeTherapy?.drugTarget}
                </p>
              </div>
              <div className="therapy-row-margins" data-testid="drug-class-margin">
                <p className="therapy-modal-label" data-testid="drug-class-label">
                  Drug Class
                </p>
                <p className="therapy-modal-content" data-testid="drug-class">
                  {activeTherapy?.drugClass}
                </p>
              </div>
              <div className="therapy-row-margins" data-testid="trial-phase-margin">
                <p className="therapy-modal-label" data-testid="trial-phase-label">
                  Trial Phase
                </p>
                <p className="therapy-modal-content" data-testid="trial-phase">
                  {activeTherapy?.trialPhase ? formatField(activeTherapy.trialPhase, 'trial') : '-'}
                </p>
              </div>
              <div className="therapy-row-margins" data-testid="drug-mechanism-margin">
                <p className="therapy-modal-label" data-testid="drug-mechanism-label">
                  Drug Mechanism
                </p>
                <p className="therapy-modal-content" data-testid="drug-mechanism">
                  {activeTherapy?.drugMechanism
                    ? formatField(activeTherapy.drugMechanism, 'mechanism')
                    : '-'}
                </p>
              </div>
              <div className="therapy-row-margins" data-testid="action-type-margin">
                <p className="therapy-modal-label" data-testid="action-type-label">
                  Action Type
                </p>
                <p className="therapy-modal-content" data-testid="action-type">
                  {activeTherapy?.actionType}
                </p>
              </div>
              <div className="therapy-row-margins" data-testid="approved-indication-margin">
                <p className="therapy-modal-label" data-testid="approved-indication-label">
                  Approved Indication
                </p>
                <p className="therapy-modal-content" data-testid="approved-indication">
                  {activeTherapy?.approvedIndications}
                </p>
              </div>
            </div>
          )}
        </Modal>
      </>
    );
  } else {
    return null;
  }
};
