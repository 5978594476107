import React, { useEffect } from 'react';
import styles from './PatientListView.module.scss';
import { PatientTable } from '../../components/patient-table/PatientTable';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  fetchPatients,
  selectPatients,
  selectPatientsSearching,
} from '../../store/slices/patient-table.slice';
import { selectAuthState } from '../../store/slices/header.slice';
import {
  LoadingSpinnerProps,
  Spinner,
} from '../../components/error-stages/LoadingSpinner.component';
import { NoData } from '../../components/error-stages/NoDataAvailable.component';
import { SomethingWrong } from '../../components/error-stages/SomethingWentWrong.component';

const spinnerProps: LoadingSpinnerProps = {
  active: true,
  withOverlay: false,
  small: false,
  description: 'Loading Patient Data',
};

export const PatientListView: React.FC = () => {
  const dispatch = useAppDispatch();
  const patients = useAppSelector(selectPatients);
  const isAuthenticated = useAppSelector(selectAuthState);
  const isSearching = useAppSelector(selectPatientsSearching);

  useEffect(() => {
    if (patients === null) {
      dispatch(fetchPatients() as any);
    }
  }, [dispatch, patients]);

  if (isAuthenticated) {
    if (isSearching) {
      return <Spinner data-testid={'spinner'} {...spinnerProps} />;
    }
    if (patients) {
      if (patients?.length > 0) {
        return (
          <div className={styles['patient-list-view']}>
            <div className={styles['patient-list-view-container']}>
              <h1 className={styles['my-patients']}>My Patients</h1>
              <PatientTable patients={patients} />
            </div>
          </div>
        );
      } else {
        // patients not null but patients' length === 0
        return <NoData data-testid={'no-data'} />;
      }
    } else {
      // patients null
      return <SomethingWrong data-testid={'something-wrong'} />;
    }
  } else {
    return null;
  }
};
