import React from 'react';
import { useEffect } from 'react';
import { Auth } from '@aws-amplify/auth';
import { useAppDispatch } from './store/hooks';
import { changeAuthState } from './store/slices/header.slice';

export const ReduxRefresh: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    // Hacky should be in a thunk probably
    // Todo: maybe check with Laura about hacky thunk?  useCallback?
    (async () => {
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        dispatch(changeAuthState(true));
      } else {
        dispatch(changeAuthState(false));
      }
    })();
  });

  return null;
};

export default ReduxRefresh;
