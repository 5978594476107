import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import { NavHeader } from './components/nav-header/NavHeader';
import { PatientDashboardPage } from './pages/patient-dashboard/PatientDashboard';
import { SignIn } from './pages/auth/SignIn';
import { PatientListView } from './pages/patient-list-view/PatientListView';
import { AuthSetup } from './components/auth/AuthSetup';
import ReduxRefresh from './ReduxRefresh';

export const App: React.FC = () => {
  return (
    <div className="App">
      <AuthSetup />
      <ReduxRefresh />
      <Router>
        <div className="SygHeader">
          <NavHeader />
        </div>
        <Switch>
          <Route exact path="/" component={() => <SignIn />} />
          <Route exact path="/patients" component={() => <PatientListView />} />
          <Route path="/patients/dashboard" component={() => <PatientDashboardPage />} />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
