import React, { useState } from 'react';
import { Tag, Tile, Link } from 'carbon-components-react';
import styles from './efficacy.module.scss';
import { TherapyEfficacyCardData } from '../../models/TherapyEfficacyCardData';
import { TherapyModal } from '../therapy-modal/TherapyModal.component';

export interface EfficacyTableProps {
  therapy: TherapyEfficacyCardData[];
  currentId: string;
}

export const EfficacyTable: React.FC<EfficacyTableProps> = (props: EfficacyTableProps) => {
  const therapyTabLink = '/patients/dashboard?pid=' + props.currentId + '#therapy-details';
  const [modalToggle, setModalToggle] = useState(false);
  const [modalIDToggle, setModalIDToggle] = useState('');

  return (
    <Tile className={styles['card']} data-testid="efficacy-card">
      <div className={styles['header']} data-testid="efficacy-header">
        <div>
          <div className={styles['title']} data-testid="efficacy-title">
            Predicted Therapeutic Efficacy
          </div>
          <div className={styles['title-link']} data-testid="efficacy-link">
            <Link href={therapyTabLink} className={styles['title-link']}>
              See Details
            </Link>
          </div>
        </div>
      </div>
      <div className={styles['table-margins']} data-testid="efficacy-table">
        <table className={styles['table-margins']}>
          <thead>
            <tr>
              <th className={styles['therapy-name']} data-testid="efficacy-therapy-name">
                Therapy
              </th>
              <th className={styles['therapy-type']} data-testid="efficacy-type">
                Type
              </th>
              <th className={styles['therapy-btn-col']} data-testid="efficacy-therapy-ranking">
                Therapy Ranking
              </th>
            </tr>
          </thead>
          <tbody data-testid="therapy-tbody">
            {props.therapy.map((ele) => (
              <tr key={ele.therapyId}>
                <td className="text-content" data-testid="therapy-column">
                  {/* Link for Therapy Name  */}
                  <Link
                    onClick={() => {
                      setModalToggle(true);
                      setModalIDToggle(ele.therapyId);
                    }}
                    data-testid="link"
                  >
                    {ele.name}
                  </Link>
                </td>
                <td className="text-content" data-testid="type-column">
                  {ele.type}{' '}
                </td>
                {/* Therapy Ranking Column */}
                {ele.efficacy > 80 && (
                  <td className={styles['tag-view']} data-testid="therapy-ranking-column">
                    <Tag className={styles['tag-very-high']} data-testid="efficacyTag">
                      Very High
                    </Tag>
                  </td>
                )}
                {ele.efficacy > 60 && ele.efficacy <= 80 && (
                  <td className={styles['tag-view']}>
                    <Tag className={styles['tag-high']}>High</Tag>
                  </td>
                )}
                {ele.efficacy > 40 && ele.efficacy <= 60 && (
                  <td className={styles['tag-view']}>
                    <Tag className={styles['tag-medium']}>Medium</Tag>
                  </td>
                )}
                {ele.efficacy > 20 && ele.efficacy <= 40 && (
                  <td className={styles['tag-view']}>
                    <Tag className={styles['tag-low']}>Low</Tag>
                  </td>
                )}
                {ele.efficacy <= 20 && (
                  <td className={styles['tag-view']}>
                    <Tag className={styles['tag-very-low']}>Very Low</Tag>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        <TherapyModal
          open={modalToggle}
          therapyId={modalIDToggle}
          onRequestClose={() => setModalToggle(false)}
        />
      </div>
    </Tile>
  );
};
