import React, { useState } from 'react';
import { Tabs, Tab } from 'carbon-components-react';
import './DashboardTab.scss';
import { EfficacyTable } from '../efficacy-table/EfficacyTable.component';
import { TherapyData } from '../../models/TherapyData';
import TherapyTab from '../therapies/TherapyTab';
import { AnalysisData } from '../../models/AnalysisData';
import { PatientData } from '../../models/PatientData';
import { RiskClassificationSubtypesActive } from '../../models/RiskClassificationSubtypesActiveData';
import { SurvivalCurve, buildSurvivalCurveProps } from '../survival-curve-graph/SurvivalCurve';
import { RiskProfileCard } from '../risk-profile-card/RiskProfileCard';
import { TherapyRecommendationDetails } from '../../models/TherapyRecommendationData';
import { RiskDetailsHistogramTile } from '../risk-details-histogram-tile/RiskDetailsHistogramTile';
import { RiskModel } from '../risk-details-model/RiskModel.component';
import { CalculationModal } from '../calculation-modal/CalculationModal.component';

//made array of tabs to reference them by index
const tabs = ['dashboard', 'risk-details', 'therapy-details'];

//goes through tabs, if can't find then default to first tab
const getTabIndex = () => {
  const index = tabs.findIndex((tab) => location.hash.slice(1, location.hash.length) === tab);
  return index >= 0 ? index : 0;
};

export interface DashBoardTabProps {
  therapy: TherapyData[];
  curID: string;
  analysis: AnalysisData;
  patient: PatientData;
  riskAc: RiskClassificationSubtypesActive[];
  risk: string[];
}

function ReformatTypes(therapyData, therapyReformatType) {
  // Hacked way of getting formatted types per xd Design

  let resultString = '';
  let numOfTypes = 0;

  if (therapyData.type === 'SOC') {
    if (numOfTypes > 0) {
      resultString = resultString.concat(', ');
    }

    resultString = resultString.concat(' Standard of Care');
    numOfTypes++;
  }

  if (therapyData.type.indexOf('Clinical Trials') >= 0) {
    if (numOfTypes > 0) {
      resultString = resultString.concat(', ');
    }

    resultString = resultString.concat(' Clinical Trials');
    numOfTypes++;
  }

  if (therapyData.type.indexOf('Off-target') >= 0) {
    if (numOfTypes > 0) {
      resultString = resultString.concat(', ');
    }

    resultString = resultString.concat(' Off Label Use');
    numOfTypes++;
  }

  if (numOfTypes === 0) {
    return therapyReformatType;
  }

  return resultString;
}

export const DashboardTab: React.FC<DashBoardTabProps> = (props: DashBoardTabProps) => {
  const therapies: any = [];
  const riskClassification: any = [];
  const [calcModalToggle, setCalcModalToggle] = useState(false);
  const activeLabel = props.riskAc[0].label;

  props.therapy.map((therapyData) => {
    let therapyAnalysisDetails;

    if (
      props.analysis.therapyRecommendationDetails.find(
        (details: TherapyRecommendationDetails) => details.id === therapyData.therapyId
      )
    ) {
      therapyAnalysisDetails = props.analysis.therapyRecommendationDetails.find(
        (details: TherapyRecommendationDetails) => details.id === therapyData.therapyId
      );
    }

    let therapyReformatType = therapyData.type;
    therapyReformatType = ReformatTypes(therapyData, therapyReformatType);

    therapies.push({
      therapyId: therapyData.therapyId,
      name: therapyData.name,
      type: therapyReformatType,
      efficacy: therapyAnalysisDetails.therapyScore * 100,
    });
  });

  props.riskAc.map((riskClassificationData) => {
    riskClassification.push({
      accuracy: riskClassificationData.accuracy,
      probability: riskClassificationData.probability,
      class: riskClassificationData.class,
      quality: riskClassificationData.quality,
      pfs: riskClassificationData.pfs,
      relapse: riskClassificationData.relapsePrediction,
    });
  });

  const data = { therapy: therapies.slice(0, 10) };

  const dataRisk = { riskAc: riskClassification.slice(0, 1) };
  const activeIndex = props.risk.indexOf(activeLabel);
  const lastIndex = props.risk.length;
  let riskNotActive = props.risk;
  if (props.risk.includes(activeLabel)) {
    riskNotActive = props.risk.slice(0, activeIndex);
    riskNotActive = riskNotActive.concat(props.risk.slice(activeIndex + 1, lastIndex));
  }
  const survivalCurveProps = buildSurvivalCurveProps(
    props.patient.name,
    props.analysis.riskPredictionDetails.riskScore * 100,
    props.analysis.riskPredictionDetails.medianProgressionFreeSurvival
  );

  return (
    <>
      <Tabs selected={getTabIndex()}>
        <Tab
          id="dashboard-tab"
          className="patient-dashboard-tab"
          label="Dashboard"
          href="#dashboard"
          onClick={() => (window.location.hash = tabs[0])}
        >
          <h1 className="dashboard-tile-header">
            These findings are based on the CoMMpass dataset
          </h1>
          <a onClick={() => setCalcModalToggle(true)} className="risk-calculated-link">
            How did we calculate risk?
          </a>
          <RiskProfileCard
            analysis={props.analysis}
            currentId={props.curID}
            patientName={props.patient.name}
          />
          <EfficacyTable therapy={data.therapy} currentId={props.curID} />
        </Tab>
        <Tab
          id="risk-details-tab"
          className="patient-dashboard-tab"
          label="Risk Details"
          href="#risk-details"
          onClick={() => (window.location.hash = tabs[1])}
        >
          {getTabIndex() === 1 && (
            <div>
              <RiskDetailsHistogramTile
                analysis={props.analysis}
                relapse={props.riskAc[0].relapsePrediction}
                patientName={props.patient.name}
                toggleCalcModal={setCalcModalToggle}
              />
              <SurvivalCurve {...survivalCurveProps} />
              <RiskModel
                title={'Model Used for Calculating Risk'}
                riskAc={dataRisk.riskAc}
                riskNotActive={riskNotActive}
                active={activeLabel}
              />
            </div>
          )}
        </Tab>
        <Tab
          id="therapy-details-tab"
          className="patient-dashboard-tab"
          label="Therapy Details"
          href="#therapy-details"
          onClick={() => (window.location.hash = tabs[2])}
        >
          <TherapyTab analysis={props.analysis} />
        </Tab>
      </Tabs>
      <CalculationModal open={calcModalToggle} onRequestClose={() => setCalcModalToggle(false)} />
    </>
  );
};
