import React from 'react';
import { Grid, Row, Column } from 'carbon-components-react';
import './DemographicsGrid.scss';
import { PatientDemographicsGridData } from '../../models/PatientDemographicGridData';

export interface DemographicsGridProps {
  patient: PatientDemographicsGridData;
}

//since Date object can't be directly displayed
function displayDate(inputDate: Date) {
  const date = new Date(inputDate);
  const month = date.getMonth() + 1; //months in JS start from 0
  const day = date.getUTCDate();
  const year = date.getFullYear();

  //to allow a 0 before a month or day less than 10
  const showDate =
    (month < 10 ? '0' + month : month) + '/' + (day < 10 ? '0' + day : day) + '/' + year;

  return showDate;
}

function displayDataTypes(inputArray: Array<string>) {
  const spacedArray = inputArray.join(', ');
  return spacedArray;
}

export const DemographicsGrid: React.FC<DemographicsGridProps> = (props: DemographicsGridProps) => {
  return (
    <Grid condensed>
      <Row>
        {/*contains Diagnosis, Report Date, Age, Sex*/}
        <Column data-testid="column 1">
          {/*first row*/}
          <Row className="first-row" data-testid="row 1">
            <Column>
              <div className="grid-box-content">
                <p>Diagnosis</p>
                <h1>{props.patient.diagnosis ? props.patient.diagnosis : '-'}</h1>
              </div>
            </Column>
          </Row>
          {/*second row*/}
          <Row className="second-row" data-testid="row 2">
            <Column>
              <div className="grid-box-content">
                <p>Report Date</p>
                <h1>
                  {props.patient.reportOfDate ? displayDate(props.patient.reportOfDate) : '-'}
                </h1>
              </div>
            </Column>
            <Column>
              <div className="grid-box-content">
                <p>Age</p>
                <h1>{props.patient.age ? props.patient.age : '-'}</h1>
              </div>
            </Column>
            <Column>
              <div className="grid-box-content">
                <p>Sex</p>
                <h1>{props.patient.sex ? props.patient.sex : '-'}</h1>
              </div>
            </Column>
          </Row>
        </Column>

        {/*contains Date of Diagnosis, Stage, Race, Performance Status*/}
        <Column data-testid="column 2">
          {/*first row*/}
          <Row className="first-row" data-testid="row 1">
            <Column>
              <div className="grid-box-content">
                <p>Date of Diagnosis</p>
                <h1>
                  {props.patient.dateOfDiseaseDiagnosis
                    ? displayDate(props.patient.dateOfDiseaseDiagnosis)
                    : '-'}
                </h1>
              </div>
            </Column>
            <Column>
              <div className="grid-box-content">
                <p>Stage</p>
                <h1>{props.patient.diseaseStage ? props.patient.diseaseStage : '-'}</h1>
              </div>
            </Column>
          </Row>
          {/*second row*/}
          <Row className="second-row" data-testid="row 2">
            <Column>
              <div className="grid-box-content">
                <p>Race</p>
                <h1>{props.patient.race ? props.patient.race : '-'}</h1>
              </div>
            </Column>
            <Column>
              <div className="grid-box-content">
                <p>Performance Status</p>
                <h1>{props.patient.performanceStatus ? props.patient.performanceStatus : '-'}</h1>
              </div>
            </Column>
          </Row>
        </Column>

        {/*contains Type, Date Types, Institution*/}
        <Column data-testid="column 3">
          {/*first row*/}
          <Row className="first-row" data-testid="row 1">
            <Column>
              <div className="grid-box-content">
                <p>Type</p>
                <h1>{props.patient.type ? props.patient.type : '-'}</h1>
              </div>
            </Column>
            <Column id="last-column-right-box">
              <div className="grid-box-content">
                <p>Data Types</p>
                <h1>{props.patient.dataTypes ? displayDataTypes(props.patient.dataTypes) : '-'}</h1>
              </div>
            </Column>
          </Row>
          {/*second row*/}
          <Row className="second-row" data-testid="row 2">
            <Column id="last-column-right-box">
              <div className="grid-box-content">
                <p>Institution</p>
                <h1>
                  {props.patient.managingOrganization ? props.patient.managingOrganization : '-'}
                </h1>
              </div>
            </Column>
          </Row>
        </Column>
      </Row>
    </Grid>
  );
};
