/* NoDataAvailable.component.tsx
 *
 *  This .tsx file contains code for the No Data Available Error State View
 */

//Below are the imports required for this file:
//
import React from 'react';
import { ReactComponent as NoDataChart } from './../../assets/graph.svg';
import { UnorderedList, ListItem } from 'carbon-components-react';
import './nodata.component.scss';

export const NoData: React.FC = () => {
  return (
    <div className="graph">
      <NoDataChart />
      <hr className="divider"></hr>
      <div>
        <p className="no-data-available">No Data Available</p>
        <p className="here-might-be-why">Here's might be why...</p>
        <div>
          <UnorderedList>
            <ListItem>
              <span>No patient data to view</span>
            </ListItem>
            <ListItem>
              <span>No analyses to view</span>
            </ListItem>
            <ListItem>
              <span>There was a problem retrieving the data, please contact admin@synomics.net</span>
            </ListItem>
          </UnorderedList>
        </div>
      </div>
    </div>
  );
};

/*
 * EOF
 */
