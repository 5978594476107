import { BaseAPI } from './BaseAPI';
import { PatientListItemData } from '../models/PatientListItemData';
import { PatientData } from '../models/PatientData';
import currentEnvironment from '../config';

export class PatientAPI extends BaseAPI {
  constructor() {
    super();
  }

  PATIENTS_API_URL = currentEnvironment.config.Api.apiBaseUrl + '/patients';

  castAsPatientListItemData(object): PatientListItemData {
    return {
      patientId: object.patientId,
      oncologistId: object.oncologistId,
      name: object.name,
      medicalRecordId: object.medicalRecordId,
      age: object.age,
      diseaseName: object.diseaseName,
    } as PatientListItemData;
  }

  castAsPatientDataList(list: any[]): PatientListItemData[] {
    const patientDataList: PatientListItemData[] = [];
    list.map((item) => {
      const pDInstance = this.castAsPatientListItemData(item);
      patientDataList.push(pDInstance);
    });
    return patientDataList;
  }

  async getPatients() {
    const url = this.PATIENTS_API_URL;
    let result = await this.get(url);
    if (result != null) {
      result = this.castAsPatientDataList(result);
    }
    return result;
  }

  async getPatientByPatientId(patientId: string) {
    const combinedUrl = this.PATIENTS_API_URL + '/' + patientId;
    let result = await this.get(combinedUrl);
    if (result != null) {
      result = result as PatientData;
    }
    return result;
  }
}

export const patientApi = new PatientAPI();
