import React from 'react';
import { Tile, Link, Tag } from 'carbon-components-react';
import './RiskProfileCard.scss';
import { AnalysisData } from '../../models/AnalysisData';
import { RiskHistogram } from '../risk-histogram/RiskHistogram';
import { Name } from '../../models/PatientListItemData';

export interface RiskProfileCardProps {
  analysis: AnalysisData;
  currentId: string;
  patientName: Name;
}

export function roundedRiskProbability(inputNumber: number) {
  const roundedNumber = inputNumber.toFixed(2);

  return roundedNumber;
}

export function addPercentileOrdinals(inputPercentile: number) {
  const lastDigitOnly = inputPercentile % 10;
  const st = 'st ';
  const nd = 'nd ';
  const rd = 'rd ';
  const th = 'th ';

  if (lastDigitOnly === 1) return st;
  else if (lastDigitOnly === 2) return nd;
  else if (lastDigitOnly === 3) return rd;
  else return th;
}

export function chooseRiskColors(riskScore: number) {
  let riskLevel = '';

  if (riskScore <= 50) {
    riskLevel = 'Low';
  } else if (riskScore > 50 && riskScore <= 60) {
    riskLevel = 'High';
  } else {
    riskLevel = 'Extreme';
  }
  return riskLevel;
}

export const RiskProfileCard: React.FC<RiskProfileCardProps> = (props: RiskProfileCardProps) => {
  const riskDetailsTabLink = '/patients/dashboard?pid=' + props.currentId + '#risk-details';
  return (
    <Tile className="card" data-testid="risk-profile-card">
      <div className="header" data-testid="risk-profile-header">
        <p>
          <span className="title" data-testid="risk-profile-title">
            Risk Profile
          </span>
          <span className="title-link" data-testid="risk-profile-link">
            <Link href={riskDetailsTabLink} className="title-link" data-testid="risk-profile-href">
              See Details
            </Link>
          </span>
        </p>
      </div>
      <div className="sygnal-risk" data-testid="sygnal-risk">
        <h1 className="sygnal-risk-score" data-testid="sygnal-risk-score">
          SYGNAL Risk Score
        </h1>
        <h1
          className={`risk-profile-risk-score ${chooseRiskColors(
            props.analysis.riskPredictionDetails.riskScore * 100
          )}-color`}
          data-testid="risk-score-in-color"
        >
          {chooseRiskColors(props.analysis.riskPredictionDetails.riskScore * 100)} Risk:{' '}
          {roundedRiskProbability(props.analysis.riskPredictionDetails.riskScore * 100)}
        </h1>
        <div
          className={`risk-percentile-box ${chooseRiskColors(
            props.analysis.riskPredictionDetails.riskScore * 100
          )}-color`}
          data-testid="risk-percentile-box"
        >
          <h1 className="risk-percentile-box-text" data-testid="risk-percentile-box-text">
            {props.analysis.riskPercentile}
            <sup className="percentile-ordinal">
              {addPercentileOrdinals(props.analysis.riskPercentile)}
            </sup>
            risk percentile of more than 800 patients
          </h1>
        </div>
        <div className="risk-profile-card-histogram" data-testid="risk-profile-card-histogram">
          <RiskHistogram
            analysis={props.analysis}
            patientName={props.patientName}
            height={'293px'}
          />
        </div>
        <hr className="line" data-testid="first-line"></hr>
        <div className="abnormalities-present" data-testid="abnormalities-present">
          <h1>Abnormalities Present Based on Cytogenetics</h1>
          <Tag className="abnormalities-tag" data-testid="abnormalities-tag">
            {props.analysis.riskPredictionDetails.riskClassificationSubtypesActive[0].label}
          </Tag>
        </div>
        <hr className="line" data-testid="second-line"></hr>
        <div className="median-progression" data-testid="median-progression">
          <h1 className="median-progression-title" data-testid="median-progression-title">
            Median Progression-Free Survival
          </h1>
          <div
            className={`months-box ${chooseRiskColors(
              props.analysis.riskPredictionDetails.riskScore * 100
            )}-color`}
            data-testid="months-box"
          >
            <h1 className="months-box-text" data-testid="months-text">
              {props.analysis.riskPredictionDetails.medianProgressionFreeSurvival} months
            </h1>
          </div>
          <p data-testid="paragraph">
            Predicted Progression Free Survival (PFS) for similar patients based on coMMpass data
          </p>
        </div>
      </div>
    </Tile>
  );
};
