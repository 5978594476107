import currentEnvironment from '../config';
import { AnalysisData } from '../models/AnalysisData';
import { BaseAPI } from './BaseAPI';

export class AnalysisAPI extends BaseAPI {
  BASE_ANALYSIS_GET_URL = currentEnvironment.config.Api.apiBaseUrl + '/analysis';

  async getAnalysisByPatientId(patientId: string) {
    let result = await this.get(this.BASE_ANALYSIS_GET_URL + '/' + patientId);
    if (result != null) {
      result = result as AnalysisData;
    }
    return result;
  }
}

export const analysisApi = new AnalysisAPI();
