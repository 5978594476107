import React from 'react';
import styles from './TherapyTable.module.scss';

interface TherapyRankingLabelProps {
  score: number;
}

export const TherapyRankingLabel = (props: TherapyRankingLabelProps) => {
  const ranking = Math.floor(props.score / 20);
  if (ranking === 4) {
    return (
      <div className={styles['label-container']} id={styles['very-high-label']}>
        <div>Very High</div>
      </div>
    );
  } else if (ranking === 3) {
    return (
      <div className={styles['label-container']} id={styles['high-label']}>
        <div>High</div>
      </div>
    );
  } else if (ranking === 2) {
    return (
      <div className={styles['label-container']} id={styles['medium-label']}>
        <div>Medium</div>
      </div>
    );
  } else if (ranking === 1) {
    return (
      <div className={styles['label-container']} id={styles['low-label']}>
        <div>Low</div>
      </div>
    );
  } else if (ranking === 0) {
    return (
      <div className={styles['label-container']} id={styles['very-low-label']}>
        <div>Very Low</div>
      </div>
    );
  } else {
    return null;
  }
};

export default TherapyRankingLabel;
