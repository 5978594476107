import { RootState } from '../store';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AnalysisData } from '../../models/AnalysisData';
import { analysisApi } from '../../api/AnalysisAPI';

export const ANALYSIS_SLICE_NAME = 'analysis';

interface AnalysisState {
  currentAnalysis: AnalysisData | null;
  analysisSearching: boolean;
  analysisError: boolean;
}

export const initialState: AnalysisState = {
  currentAnalysis: null,
  analysisSearching: false,
  analysisError: false,
};

export const fetchAnalysis = createAsyncThunk<AnalysisData | null, string>(
  `${ANALYSIS_SLICE_NAME}/fetchAnalysis`,
  async (patientId) => {
    return await analysisApi.getAnalysisByPatientId(patientId);
  }
);

export const analysisSlice = createSlice({
  name: ANALYSIS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAnalysis.pending, (state) => {
        return {
          ...state,
          currentAnalysis: null,
          analysisSearching: true,
          analysisError: false,
        };
      })
      .addCase(fetchAnalysis.fulfilled, (state, action) => {
        return {
          ...state,
          analysisSearching: false,
          analysisError: false,
          currentAnalysis: action.payload,
        };
      })
      .addCase(fetchAnalysis.rejected, (state) => {
        return {
          ...state,
          analysisSearching: false,
          analysisError: true,
          currentAnalysis: null,
        };
      });
  },
});

export const analysisReducer = analysisSlice.reducer;

export const selectCurrentAnalysis = (state: RootState): AnalysisData | null =>
  state.analysis.currentAnalysis;
export const selectAnalysisSearching = (state: RootState): boolean =>
  state.analysis.analysisSearching;
export const selectAnalysisError = (state: RootState): boolean => state.analysis.analysisError;
