import { RootState } from '../store';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PatientData } from '../../models/PatientData';
import { PatientAPI } from '../../api/PatientAPI';

export const PATIENT_SLICE_NAME = 'patient';

const api = new PatientAPI();

interface PatientState {
  currentPatient: PatientData | null;
  patientSearching: boolean;
  patientError: boolean;
}

export const initialState: PatientState = {
  currentPatient: null,
  patientSearching: false,
  patientError: false,
};

export const fetchPatient = createAsyncThunk<PatientData | null, string>(
  `${PATIENT_SLICE_NAME}/fetchPatient`,
  async (patientId) => {
    return await api.getPatientByPatientId(patientId);
  }
);

export const patientSlice = createSlice({
  name: PATIENT_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPatient.pending, (state) => {
        return {
          ...state,
          currentPatient: null,
          patientSearching: true,
          patientError: false,
        };
      })
      .addCase(fetchPatient.fulfilled, (state, action) => {
        return {
          ...state,
          patientSearching: false,
          patientError: false,
          currentPatient: action.payload,
        };
      })
      .addCase(fetchPatient.rejected, (state) => {
        return {
          ...state,
          patientSearching: false,
          patientError: true,
          currentPatient: null,
        };
      });
  },
});

export const patientReducer = patientSlice.reducer;

export const selectCurrentPatient = (state: RootState): PatientData | null =>
  state.patient.currentPatient;
export const selectPatientSearching = (state: RootState): boolean => state.patient.patientSearching;
export const selectPatientError = (state: RootState): boolean => state.patient.patientError;
