export interface ApiConfig {
  apiBaseUrl: string;
  baseUrl: string;
}

export interface AuthOAuth {
  domain: string;
  scope: string[];
  redirectSignIn: string;
  redirectSignOut: string;
  responseType: string;
}

export interface AuthConfig {
  region: string;
  userPoolId: string;
  userPoolWebClientId: string;
  authenticationFlowType: string;
  oauth: AuthOAuth;
}

export interface Config {
  Api: ApiConfig;
  Auth: AuthConfig;
}
export class AppEnvironment {
  public readonly config: Config;
  constructor(apiConfig: ApiConfig, authConfig: AuthConfig) {
    this.config = AppEnvironment.generateConfig(apiConfig, authConfig);
  }

  static generateConfig(apiConfig: ApiConfig, authConfig: AuthConfig): Config {
    return {
      Api: apiConfig,
      Auth: {
        ...authConfig,
        oauth: {
          ...authConfig.oauth,
          redirectSignOut: apiConfig.baseUrl + authConfig.oauth.redirectSignOut,
          redirectSignIn: apiConfig.baseUrl + authConfig.oauth.redirectSignIn,
        },
      },
    };
  }
}

const LocalEnvironment = new AppEnvironment(
  {
    apiBaseUrl: 'https://api.staging.sygnomics.net/sygnomics-api',
    baseUrl: 'http://localhost:3000',
  },

  {
    region: 'us-west-2',
    userPoolId: 'us-west-2_29a4NCqtW',
    userPoolWebClientId: '3sreavbsj7jkcf8dk5f9esrakt',
    authenticationFlowType: 'USER_SRP_AUTH',
    oauth: {
      domain: 'staging-sygnomics-users.auth.us-west-2.amazoncognito.com',
      scope: ['aws.cognito.signin.user.admin', 'email', 'profile', 'openid'],
      redirectSignIn: '/patients',
      redirectSignOut: '',
      responseType: 'code',
    },
  }
);

const StageEnvironment = new AppEnvironment(
  {
    apiBaseUrl: 'https://api.staging.sygnomics.net/sygnomics-api',
    baseUrl: 'https://web.staging.sygnomics.net',
  },

  {
    region: 'us-west-2',
    userPoolId: 'us-west-2_29a4NCqtW',
    userPoolWebClientId: '3sreavbsj7jkcf8dk5f9esrakt',
    authenticationFlowType: 'USER_SRP_AUTH',
    oauth: {
      domain: 'staging-sygnomics-users.auth.us-west-2.amazoncognito.com',
      scope: ['aws.cognito.signin.user.admin', 'email', 'profile', 'openid'],
      redirectSignIn: '/patients',
      redirectSignOut: '',
      responseType: 'code',
    },
  }
);

const ProdEnvironment = new AppEnvironment(
  {
    apiBaseUrl: 'https://api.console.sygnomics.net/sygnomics-api',
    baseUrl: 'https://web.console.sygnomics.net',
  },

  {
    region: 'us-west-2',
    userPoolId: 'us-west-2_Rt3IlHO6d',
    userPoolWebClientId: 'vf0h9i7s3ik9fnmu6ji768bdj',
    authenticationFlowType: 'USER_SRP_AUTH',
    oauth: {
      domain: 'prod-sygnomics-users.auth.us-west-2.amazoncognito.com',
      scope: ['aws.cognito.signin.user.admin', 'email', 'profile', 'openid'],
      redirectSignIn: '/patients',
      redirectSignOut: '',
      responseType: 'code',
    },
  }
);

const currentSubdomain: string = /:\/\/([^/]+)/.exec(window.location.href)?.[1] ?? '';

let currentEnvironment: AppEnvironment;

switch (currentSubdomain) {
  case 'web.console.sygnomics.net':
    currentEnvironment = ProdEnvironment;
    break;
  case 'web.staging.sygnomics.net':
    currentEnvironment = StageEnvironment;
    break;
  default: {
    currentEnvironment = LocalEnvironment;
    break;
  }
}
export default currentEnvironment;
