import { configureStore } from '@reduxjs/toolkit';
import { analysisReducer, ANALYSIS_SLICE_NAME } from './slices/analysis.slice';
import { headerReducer, HEADER_SLICE_NAME } from './slices/header.slice';
import { patientReducer, PATIENT_SLICE_NAME } from './slices/patient.slice';
import { patientsListReducer, PATIENTS_LIST_SLICE_NAME } from './slices/patient-table.slice';
import { THERAPY_SLICE_NAME, therapyReducer } from './slices/therapy.slice';

// ...

export const store = configureStore({
  reducer: {
    [HEADER_SLICE_NAME]: headerReducer,
    [PATIENT_SLICE_NAME]: patientReducer,
    [ANALYSIS_SLICE_NAME]: analysisReducer,
    [PATIENTS_LIST_SLICE_NAME]: patientsListReducer,
    [THERAPY_SLICE_NAME]: therapyReducer,
    // Add Reducers here
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
