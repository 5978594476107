import React from 'react';
import { Tile } from 'carbon-components-react';
import './RiskDetailsHistogramTile.scss';
import { AnalysisData } from '../../models/AnalysisData';
import { RelaspePrediction } from '../../models/RelapsePredictionData';
import { RiskHistogram } from '../risk-histogram/RiskHistogram';
import { Name } from '../../models/PatientListItemData';

export interface RiskDetailsHistogramTileProps {
  analysis: AnalysisData;
  relapse: RelaspePrediction[];
  patientName: Name;
  toggleCalcModal: any;
}

export function roundedRiskProbability(inputNumber: number) {
  const roundedNumber = inputNumber.toFixed(2);

  return roundedNumber;
}

export function addPercentileOrdinals(inputPercentile: number) {
  const lastDigit = inputPercentile % 10;
  const endDigits = inputPercentile % 100;
  const st = 'st ';
  const nd = 'nd ';
  const rd = 'rd ';
  const th = 'th ';

  if (endDigits >= 10 && endDigits <= 20) return th;

  if (lastDigit === 1) return st;
  if (lastDigit === 2) return nd;
  if (lastDigit === 3) return rd;
  else return th; //..0th
}

export function roundPercentileDownToTenth(inputPercentile: number) {
  const roundedDownTenth = Math.floor(inputPercentile / 10) * 10;

  return roundedDownTenth;
}

export function chooseRiskColors(riskScore: number) {
  let riskLevel = '';

  if (riskScore <= 50) {
    riskLevel = 'Low';
  } else if (riskScore > 50 && riskScore <= 60) {
    riskLevel = 'High';
  } else {
    riskLevel = 'Extreme';
  }
  return riskLevel;
}

export const RiskDetailsHistogramTile: React.FC<RiskDetailsHistogramTileProps> = (
  props: RiskDetailsHistogramTileProps
) => {
  return (
    <div className="histogram-tile-component" data-testid="histogram-tile-component">
      <h1 className="risk-details-profile" data-testid="risk-details-profile">
        Risk Profile
      </h1>
      <h1 className="risk-details-profile-sentence" data-testid="risk-details-sentence">
        These findings are based on the CoMMpass dataset
      </h1>
      <a onClick={() => props.toggleCalcModal(true)} className="risk-calculated-link">
        How did we calculate risk?
      </a>
      <Tile className="histogram-tile" data-testid="histogram-tile">
        <div className="risk-details-card-histogram" data-testid="risk-details-card-histogram">
          <span className="risk-score-span" data-testid="risk-score-span">
            Risk Score
          </span>
          <RiskHistogram
            analysis={props.analysis}
            patientName={props.patientName}
            height={'465px'}
          />
        </div>
        <div className="card-information-on-right" data-testid="card-information-on-right">
          <h1
            className={`risk-details-risk-score ${chooseRiskColors(
              props.analysis.riskPredictionDetails.riskScore * 100
            )}-color`}
            data-testid="risk-details-risk-score"
          >
            {chooseRiskColors(props.analysis.riskPredictionDetails.riskScore * 100)} Risk at{' '}
            {roundedRiskProbability(props.analysis.riskPredictionDetails.riskScore * 100)}
          </h1>
          <div
            className={`risk-details-percentile-box ${chooseRiskColors(
              props.analysis.riskPredictionDetails.riskScore * 100
            )}-color`}
            data-testid="risk-details-percentile-box"
          >
            <h1 className="risk-percentile-box-text" data-testid="risk-percentile-box-text">
              {props.analysis.riskPercentile}
              <sup className="percentile-ordinal">
                {addPercentileOrdinals(props.analysis.riskPercentile)}
              </sup>
              risk percentile of more than 800 patients
            </h1>
          </div>
          <p data-testid="risk-percentile-paragraph">
            The SYGNAL model predicts this patient is in the{' '}
            {roundPercentileDownToTenth(props.analysis.riskPercentile)}th percentile for{' '}
            {chooseRiskColors(props.analysis.riskPredictionDetails.riskScore * 100).toLowerCase()}{' '}
            risk of relapsing with {props.analysis.diseaseName.toLowerCase()}.
          </p>

          <div className="relapse-rate" data-testid="relapse-rate">
            <h1 className="relapse-rate-title" data-testid="relapse-rate-title">
              Relapse Rate
            </h1>
            <h1 className="relapse-rate-year" data-testid="relapse-rate-year-1">
              <span id="relapse-rate-1" data-testid="relapse-rate-1">
                {Math.round(props.relapse[0].rate * 100) + '%'}
              </span>
              <span>in {props.relapse[0].period} year</span>
            </h1>
            <h1 className="relapse-rate-year" data-testid="relapse-rate-year-2">
              <span id="relapse-rate-2" data-testid="relapse-rate-2">
                {Math.round(props.relapse[1].rate * 100) + '%'}
              </span>
              <span>in {props.relapse[1].period} years</span>
            </h1>
            <h1
              className="relapse-rate-year"
              id="relapse-5-years"
              data-testid="relapse-rate-year-5"
            >
              <span id="relapse-rate-5" data-testid="relapse-rate-5">
                {Math.round(props.relapse[2].rate * 100) + '%'}
              </span>
              <span>in {props.relapse[2].period} years</span>
            </h1>
          </div>
        </div>
      </Tile>
    </div>
  );
};
