import { Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { changeAuthState } from '../../store/slices/header.slice';
import { useAppDispatch } from '../../store/hooks';
import currentEnvironment from '../../config';
import { getAccessToken } from './auth.helper';

export const AuthSetup: React.FC = () => {
  const dispatch = useAppDispatch();

  Auth.configure({
    Auth: currentEnvironment.config.Auth,
  });

  Hub.listen('auth', ({ payload: { event, data } }) => {
    switch (event) {
      case 'cognitoHostedUI':
        getUser().then(() => {
          getAccessToken();
          dispatch(changeAuthState(true));
        });
        break;
      case 'cognitoHostedUI_failure':
        console.log('Sign in failure', data);
        break;
    }
  });

  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch(() => console.log('No user signed in'));
  }

  return null;
};

export default AuthSetup;
