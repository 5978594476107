import React from 'react';
import { Grid, Tag, Tile } from 'carbon-components-react';
import styles from './riskmodel.module.scss';
import './riskmodel.module.scss';
import { RiskModelData } from '../../models/RiskModelData';
import { RiskClassificationSubtypesActive } from '../../models/RiskClassificationSubtypesActiveData';

export interface RiskModelProps {
  riskAc: RiskModelData[];
  riskNotActive: string[];
  active: string;
  title: string;
}

export const RiskModel: React.FC<RiskModelProps> = (props: RiskModelProps) => {
  return (
    <div className={styles['risk-margin']} data-testid="risk-margin">
      <p className={styles['widget-title']} data-testid="title">
        {props.title}
      </p>
      <p className={styles['sub-heading']} data-testid="first-sub-heading">
        Abnormalities Not Present Based on Cytogenetics
      </p>
      <div className={styles['align']}>
        {props.riskNotActive.map(
          (ele) =>
            ele.toLowerCase() !== 'agnostic' && (
              <Tag className={styles['not-present-tags']} data-testid="gray-tags">
                {ele}
              </Tag>
            )
        )}
      </div>
      <p className={styles['sub-heading']} data-testid="second-sub-heading">
        Abnormalities Present Based on Cytogenetics
      </p>
      <div>
        <Tile className={styles['risk-card']} data-testid="risk-model-card">
          <Tag className={styles['tag-present']} data-testid="riskTag">
            {props.active}
          </Tag>
          <table>
            <thead>
              <tr>
                <th data-testid="Accuracy">Accuracy</th>
                <th data-testid="SYGNAL Probability">SYGNAL Probability</th>
                <th data-testid="SYGNAL Class">SYGNAL Class</th>
                <th data-testid="Quality">Quality</th>
                <th data-testid="SYGNAL PFS">SYGNAL PFS (m)</th>
                <th data-testid="Relapse Rate">
                  Relapse Rate
                  <th></th>
                  <th></th>
                  <th></th>
                </th>
              </tr>
            </thead>
            <tbody data-testid="riskModel-tbody">
              {props.riskAc.map((activeData) => (
                <tr className={styles['bold']}>
                  <td data-testid="accuracy-percentage">
                    {Math.round(activeData.accuracy * 100) + '%'}
                  </td>
                  <td data-testid="sygnal-probability-percentage">
                    {Math.round(activeData.probability * 100) + '%'}
                  </td>
                  <td data-testid="sygnal-class-percentage">{activeData.class}</td>
                  <td data-testid="quality-percentage">{activeData.quality}</td>
                  <td data-testid="sygnal-pfs-percentage">{activeData.pfs}</td>

                  <td className={styles['flex-col']} data-testid="relapse-rate-percentages">
                    <td
                      className={styles['padding-uniq']}
                      data-testid="relapse-rate-percentage-1yr"
                    >
                      {Math.round(activeData.relapse[0].rate * 100) + '%'}
                    </td>
                    <td style={{ paddingLeft: 30 }} data-testid="relapse-rate-percentage-2yr">
                      {Math.round(activeData.relapse[1].rate * 100) + '%'}
                    </td>
                    <td style={{ paddingLeft: 60 }} data-testid="relapse-rate-percentage-5yr">
                      {Math.round(activeData.relapse[2].rate * 100) + '%'}
                    </td>
                    <td
                      className={styles['yr']}
                      style={{ paddingLeft: 0 }}
                      data-testid="relapse-rate-1yr"
                    >
                      {activeData.relapse[0].period + 'yr'}
                    </td>
                    <td
                      className={styles['yr']}
                      style={{ paddingLeft: 30 }}
                      data-testid="relapse-rate-2yr"
                    >
                      {activeData.relapse[1].period + 'yr'}
                    </td>
                    <td
                      className={styles['yr']}
                      style={{ paddingLeft: 60 }}
                      data-testid="relapse-rate-5yr"
                    >
                      {activeData.relapse[2].period + 'yr'}
                    </td>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Tile>
      </div>
    </div>
  );
};
