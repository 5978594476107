import React from 'react';
import { Tag } from 'carbon-components-react';
import { Button } from 'carbon-components-react';
import styles from './PatientTable.module.scss';
import '../../styles/button.scss';
import { PatientListItemData } from '../../models/PatientListItemData';
import { useAppDispatch } from '../../store/hooks';
import { fetchPatient } from '../../store/slices/patient.slice';

export interface PatientTableProps {
  patients: PatientListItemData[];
}

export const PatientTable: React.FC<PatientTableProps> = (props: PatientTableProps) => {
  return (
    <table data-testid="patient-table">
      <thead>
        <tr>
          <th className={styles['patient-name']} data-testid="columnTitleName">
            Name
          </th>
          <th className={styles['patient-MRID']} data-testid="columnTitleMRID">
            Medical Record ID
          </th>
          <th className={styles['patient-age']} data-testid="columnTitleAge">
            Age
          </th>
          <th className={styles['patient-disease']} data-testid="columnTitleDiagnosis">
            Diagnosis
          </th>
          <th className={styles['patient-btn-col']}></th>
        </tr>
      </thead>
      <tbody data-testid="patient-tbody">
        {props.patients.map(
          (ele) =>
            ele.diseaseName && (
              <tr key={ele.patientId}>
                <td className="text-content" data-testid="name">
                  {ele.name.family}, {ele.name.given}
                </td>
                <td className="text-content" data-testid="medicalRecordId">
                  {ele.medicalRecordId}
                </td>
                <td className="text-content" data-testid="age">
                  {ele.age}
                </td>
                <td className="text-content" data-testid="diseaseName">
                  {ele.diseaseName ? ele.diseaseName : '-'}
                </td>
                <td className={styles['btn-view']}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      window.location.href = '/patients/dashboard?pid=' + ele.patientId;
                    }}
                    className={styles['patient-btn']}
                    data-testid="buttonViewAnalysis"
                  >
                    View Analysis
                  </Button>
                </td>
              </tr>
            )
        )}
      </tbody>
    </table>
  );
};
