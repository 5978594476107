import { RootState } from './../store';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PatientListItemData } from '../../models/PatientListItemData';
import { patientApi } from '../../api/PatientAPI';
import currentEnvironment from '../../config';

export const PATIENTS_LIST_SLICE_NAME = 'patientsList';
//Hardcoded oncologist ID -- TODO: update with live data
export const PATIENTS_GET_URL =
  currentEnvironment.config.Api.apiBaseUrl +
  '/patients?oncologistId=ed331b25-f6e9-4c61-82af-a49aa3d46bda';

interface PatientTableState {
  patientsSearching: boolean;
  patientsError: boolean;
  patientsList: PatientListItemData[] | null;
}

export const initialState: PatientTableState = {
  patientsSearching: false,
  patientsError: false,
  patientsList: null,
};

export const fetchPatients = createAsyncThunk<PatientListItemData[] | null>(
  `${PATIENTS_LIST_SLICE_NAME}/fetchPatients`,
  async () => {
    return patientApi.getPatients();
  }
);

export const patientsListSlice = createSlice({
  name: PATIENTS_LIST_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPatients.pending, (state) => {
        return {
          ...state,
          patientsSearching: true,
          patientsError: false,
          patientsList: null,
        };
      })
      .addCase(fetchPatients.fulfilled, (state, action) => {
        return {
          ...state,
          patientsSearching: false,
          patientsError: false,
          patientsList: action.payload,
        };
      })
      .addCase(fetchPatients.rejected, (state) => {
        return {
          ...state,
          patientsSearching: false,
          patientsError: true,
          patientsList: null,
        };
      });
  },
});

export const patientsListReducer = patientsListSlice.reducer;

export const selectPatients = (state: RootState): PatientListItemData[] | null =>
  state.patientsList.patientsList;
export const selectPatientsSearching = (state: RootState): boolean =>
  state.patientsList.patientsSearching;
export const selectPatientsError = (state: RootState): boolean => state.patientsList.patientsError;
