import { TherapyData } from '../models/TherapyData';
import { BaseAPI } from './BaseAPI';
import currentEnvironment from '../config';
import { AnalysisData } from '../models/AnalysisData';

export class TherapyAPI extends BaseAPI {
  BASE_THERAPY_GET_URL = currentEnvironment.config.Api.apiBaseUrl + '/therapy';

  async getTherapiesOnAnalysis(analysis: AnalysisData) {
    const therapyPromise = analysis.therapyRecommendationDetails.map(async (therapy) => {
      const result = (await this.get(this.BASE_THERAPY_GET_URL + '/' + therapy.id)) as TherapyData;
      result.name = result.name
        .split(' ')
        .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
        .join(' ');
      return result;
    });
    return Promise.all(therapyPromise);
  }
}

export const therapyApi = new TherapyAPI();
