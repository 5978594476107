import React from 'react';
import './PatientDashboard.scss';
import { PatientBreadcrumb } from '../../components/breadcrumb/PatientBreadcrumb';
import { PatientDemographicsGridData } from '../../models/PatientDemographicGridData';
import { DashboardTab } from '../../components/dashboard-tab-structure/DashboardTab';
import { DemographicsGrid } from '../../components/patient-demographics-grid/DemographicsGrid';
import { selectAuthState } from '../../store/slices/header.slice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useEffect } from 'react';
import { selectCurrentPatient, selectPatientSearching } from '../../store/slices/patient.slice';
import {
  fetchAnalysis,
  selectAnalysisSearching,
  selectCurrentAnalysis,
} from '../../store/slices/analysis.slice';
import { fetchPatient } from '../../store/slices/patient.slice';
import {
  LoadingSpinnerProps,
  Spinner,
} from '../../components/error-stages/LoadingSpinner.component';
import { TherapyEfficacyCardData } from '../../models/TherapyEfficacyCardData';
import { RiskHistogram } from '../../components/risk-histogram/RiskHistogram';
import { fetchTherapiesOnAnalysis, selectTherapyList } from '../../store/slices/therapy.slice';

const spinnerProps: LoadingSpinnerProps = {
  active: true,
  withOverlay: false,
  small: false,
  description: 'Loading Analysis Data',
};

export const PatientDashboardPage: React.FC = () => {
  const isAuthenticated = useAppSelector(selectAuthState);
  const currentAnalysis = useAppSelector(selectCurrentAnalysis);
  const currentPatient = useAppSelector(selectCurrentPatient);
  const searchingPatient = useAppSelector(selectPatientSearching);
  const searchingAnalysis = useAppSelector(selectAnalysisSearching);
  const therapyList = useAppSelector(selectTherapyList);

  const dispatch = useAppDispatch();

  const patientInfo: PatientDemographicsGridData = {
    diagnosis: currentAnalysis?.diseaseName,
    dateOfDiseaseDiagnosis: currentAnalysis?.dateOfDiseaseDiagnosis,
    diseaseStage: currentAnalysis?.diseaseStage,
    type: currentAnalysis?.immunoglobulinType,
    dataTypes: currentAnalysis?.profilingDataTypes,
    reportOfDate: currentAnalysis?.dateOfAnalysis,
    age: currentPatient?.age,
    sex: currentPatient?.sex,
    race: currentPatient?.race,
    performanceStatus: currentPatient?.performanceStatus,
    managingOrganization: currentPatient?.managingOrganization,
  };

  const urlSearchParams = new URLSearchParams(window.location.search);
  const patientId = urlSearchParams.get('pid');

  useEffect(() => {
    if (!searchingAnalysis && currentAnalysis === null && patientId !== null) {
      dispatch(fetchPatient(patientId) as any);
      dispatch(fetchAnalysis(patientId) as any);
    }

    if (currentAnalysis && therapyList?.length === 0) {
      dispatch(fetchTherapiesOnAnalysis(currentAnalysis) as any);
    }
  }, [currentAnalysis, currentPatient, searchingAnalysis, dispatch, patientId]);

  if (searchingPatient || searchingAnalysis) {
    return <Spinner data-testid={'spinner'} {...spinnerProps} />;
  }
  if (
    isAuthenticated &&
    currentPatient &&
    currentAnalysis &&
    therapyList &&
    therapyList.length > 0
  ) {
    return (
      <div>
        <PatientBreadcrumb patient={currentPatient} />
        {/*div for page content to make margin consistent, exclude header and breadcrumb since across whole page*/}
        <div className="page-content-without-header-beadcrumbs">
          <h1 className="patient-heading">
            {currentPatient.name.given} {currentPatient.name.family} :{' '}
            {currentPatient.medicalRecordId}
          </h1>
          <DemographicsGrid patient={patientInfo} />
          <DashboardTab
            therapy={therapyList}
            curID={currentPatient?.patientId}
            patient={currentPatient}
            analysis={currentAnalysis}
            riskAc={currentAnalysis?.riskPredictionDetails?.riskClassificationSubtypesActive}
            risk={currentAnalysis?.riskPredictionDetails?.riskClassificationSubtypes}
          />
        </div>
      </div>
    );
  } else {
    return null;
  }
};
