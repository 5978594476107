import { Auth } from 'aws-amplify';

export class BaseAPI {
  public async get(url: string) {
    const session = await Auth.currentSession();
    const curToken = session.getAccessToken().getJwtToken();
    const AuthHeaderValue = `${curToken}`;
    try {
      const response = await fetch(url, { headers: { Authorization: AuthHeaderValue } });
      const body = await response.json();
      return body.result;
    } catch (error) {
      if (error.status === 404) {
        console.log(error, ' Analysis Not Found');
      } else if (error.status === 400) {
        console.log(error, ' Bad Request');
      }
    }
  }
}
