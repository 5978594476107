import {
  Header,
  HeaderName,
  HeaderMenuItem,
  HeaderGlobalBar,
  OverflowMenu,
  OverflowMenuItem,
} from 'carbon-components-react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { changeAuthState, selectAuthState } from '../../store/slices/header.slice';
import { ReactComponent as SygnomicsLogo } from './../../assets/sygnomics-logo.svg';
import { NavLink } from 'react-router-dom';
import './NavHeader.scss';
import { Auth } from '@aws-amplify/auth';
import { Loading } from '../../pages/auth/Loading';
import { ReactComponent as UserAvatar } from '../../assets/icon_avatar.svg';
import { useState } from 'react';

export interface TabInfo {
  displayString: string;
  route: string;
  id: string;
}

export interface HeaderProps {
  isAuthenticated: boolean;
  tabs: TabInfo[];
  oncologistName: string;
}

export async function getCurrentEmail() {
  return Auth.currentAuthenticatedUser()
    .then((userData) => userData)
    .catch(() => console.log('Not signed in'));
}

export const NavHeaderView: React.FC<HeaderProps> = (props: HeaderProps) => {
  const SELECTED_STRING = 'selected';

  const dispatch = useAppDispatch();

  function signOut() {
    //Changes Redux
    dispatch(changeAuthState(false));

    //Clears cognito session
    Auth.signOut({ global: true });
  }

  return (
    <>
      <Header aria-label="Sygnomics Nav Header" name="header">
        <a href="/patients">
          <SygnomicsLogo />
        </a>
        <a href="/patients">
          <HeaderName prefix={null}>Sygnomics</HeaderName>
        </a>

        {props.isAuthenticated ? (
          <>
            <div className="vertical-divider" />
            {props.tabs.map((tab) => (
              <NavLink to={tab.route} activeClassName={SELECTED_STRING} key={tab.id}>
                <HeaderMenuItem aria-label={tab.displayString}>{tab.displayString}</HeaderMenuItem>
              </NavLink>
            ))}
            <HeaderGlobalBar>
              <div className="oncologist-header" data-testid="test-oncologist-name">
                {props.oncologistName}
              </div>

              <OverflowMenu
                renderIcon={UserAvatar}
                className="dropdown-menu"
                iconClass="user-logout-icon"
                menuOffset={{ top: 16, bottom: 16, left: 10, right: 0 }}
                data-testid="test-profile-icon-button"
              >
                <OverflowMenuItem
                  itemText="Sign out"
                  className="signout-item"
                  data-testid="test-signout-button"
                  onClick={() => {
                    signOut();
                  }}
                />
              </OverflowMenu>
            </HeaderGlobalBar>
          </>
        ) : (
          <div>
            <Loading />
          </div>
        )}
      </Header>
    </>
  );
};

export const NavHeader: React.FC = () => {
  const isAuthenticated = useAppSelector(selectAuthState);
  const [email, setEmail] = useState('Sygnomics');

  getCurrentEmail()
    .then((userData) => {
      setEmail(userData.attributes.email);
    })
    .catch(() => console.log('No User Signed In'));

  const headerProps = {
    isAuthenticated: isAuthenticated,
    tabs: [
      { displayString: 'Patients', route: '/patients', id: '1' },
      // ADD new header tabs here
    ],
    oncologistName: email,
  };

  return NavHeaderView({ ...headerProps });
};
